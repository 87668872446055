import { useCallback, useState } from "react";
import SimuladorC from "./components/simulador";
import Swal from "sweetalert2";
import useLogTracker from "hooks/useLogTracker";
import formIne, { validateForm } from "./field-ine";
import XMLViewer from "react-xml-viewer";
import ReactJson from "react-json-view";

const Simulador = () => {
  const { registerCustomEvent } = useLogTracker("simulador");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [xmlStr, setXmlStr] = useState();
  const [ineRoot, setIneRoot] = useState();
  const [active, setActive] = useState(1);
  const getClassActive = () => " rounded-tl-lg  bg-brand-400 text-white";

  const calculateOferta = useCallback(
    async ({
      nomina,
      universo,
      rfc,
      ficha,
      tipo,
      curp,
      genero,
      tipoLey,
      sindicato,
      job,
      dateBirthDay,
    }) => {
      const query = `nomina=${nomina}&universo=${universo}&curp=${curp}&rfc=${rfc}&ficha=${ficha}&tipo=${tipo}&dateBirthDay=${dateBirthDay}&genero=${genero}&tipoLey=${tipoLey}&sindicato=${sindicato}&job=${job}`;
      const endpoint = `${process.env.REACT_APP_URL_BASE}/solicitudes/oferta-credito?${query}`;
      setLoading(true);
      fetch(endpoint, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          if (json && json.message) {
            setData();
            return Swal.fire({
              title: "Aviso",
              text: json.message,
            });
          }
          setData(json);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    []
  );

  const calculateOfertaFake = useCallback(
    ({
      solvencia,
      tipo,
      dependencia,
      nomina,
      universo,
      fechaIngreso,
      dateBirthDay,
      montoLiquidar,
      genero,
      tipoLey,
      sindicato,
      job,
    }) => {
      const query = `solvencia=${solvencia}&tipo=${tipo}&dependencia=${dependencia}&nomina=${nomina}&universo=${universo}&fechaIngreso=${fechaIngreso}&dateBirthDay=${dateBirthDay}&montoLiquidar=${montoLiquidar}&genero=${genero}&tipoLey=${tipoLey}&sindicato=${sindicato}&job=${job}`;
      const endpoint = `${process.env.REACT_APP_URL_BASE}/solicitudes/simulador?${query}`;
      setLoading(true);
      fetch(endpoint, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          if (json && json.message) {
            setData();
            return Swal.fire({
              title: "Aviso",
              text: json.message,
            });
          }
          setData(json);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    []
  );
  return (
    <div className="mt-10">
      <div className="flex">
        {[
          {
            text: "Simular con cliente",
            index: 1,
          },
          {
            text: "Simular con datos fijos",
            index: 2,
          },
          {
            text: "Prueba Ine",
            index: 3,
          },
        ].map((item) => {
          return (
            <button
              className={`px-4 py-2  ${
                active === item.index ? getClassActive() : "bg-gray-200"
              }`}
              onClick={() => {
                setData();
                setActive(item.index);
              }}
            >
              {item.text}
            </button>
          );
        })}
      </div>
      {active === 1 && (
        <div className="mt-10 grid grid-cols-[1fr_3fr] grid-rows-[1fr] gap-2">
          <div className="justify-ceneter flex flex-col px-10">
            <form
              className="justify-ceneter flex flex-col px-10"
              onSubmit={(event) => {
                event.preventDefault();
                const form = event.target;
                const formData = new FormData(form);
                const nomina = formData.get("nomina");
                const universo = formData.get("universo");
                const rfc = formData.get("rfc");
                const ficha = formData.get("ficha");
                const tipo = formData.get("tipo");
                const curp = formData.get("curp");
                const dateBirthDay = formData.get("dateBirthDay");
                const genero = formData.get("genero");
                const tipoLey = formData.get("tipoLey");
                const sindicato = formData.get("sindicato");

                if (!nomina) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa la nomina",
                  });
                }
                if (!universo) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa el universo",
                  });
                }
                if (!rfc) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa el rfc",
                  });
                }

                if (!ficha) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa la ficha",
                  });
                }

                if (!curp) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa el curp",
                  });
                }
                calculateOferta({
                  nomina,
                  universo,
                  rfc,
                  ficha,
                  tipo,
                  curp,
                  dateBirthDay,
                  genero,
                  tipoLey,
                  sindicato,
                });
              }}
            >
              <div className="flex flex-col">
                <label htmlFor="">Nomina</label>
                <select name="nomina" id="nomina" className="rounded-lg p-1">
                  <option value={""}>Selecciona el tipo de nomina</option>
                  <option value={1}>1</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={8}>8</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Universo</label>
                <input
                  type="text"
                  name="universo"
                  id="universo"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Curp</label>
                <input
                  type="text"
                  name="curp"
                  id="curp"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Rfc</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Ficha</label>
                <input
                  type="text"
                  name="ficha"
                  id="ficha"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Fecha de nacimiento</label>
                <input
                  type="date"
                  name="dateBirthDay"
                  id="dateBirthDay"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Genero</label>
                <select name="genero" id="genero" className="rounded-lg p-1">
                  <option value={""}>Selecciona el genero</option>
                  <option value={"H"}>H</option>
                  <option value={"M"}>M</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Tipo Ley</label>
                <input
                  type="text"
                  name="tipoLey"
                  id="tipoLey"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Sindicato</label>
                <input
                  type="text"
                  name="sindicato"
                  id="sindicato"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Tipo</label>
                <select name="tipo" id="tipo" className="rounded-lg p-1">
                  <option value={""}>Selecciona el tipo</option>
                  <option value={"Nuevo"}>Nuevo</option>
                  <option value={"Adicional"}>Adicional</option>
                  <option value={"Refinanciamiento"}>Refinanciamiento</option>
                </select>
              </div>
              <button
                className="mt-10 rounded-[10px] bg-brand-500 p-2 text-white"
                disabled={loading}
              >
                {loading ? "CALCULANDO" : "VER OFERTA"}
              </button>
            </form>
          </div>
          <div className="rounded-[20px] bg-blue-100 py-10">
            <SimuladorC oferta={data} />
          </div>
        </div>
      )}

      {active === 2 && (
        <div className="mt-10 grid grid-cols-[1fr_3fr] grid-rows-[1fr] gap-2">
          <div className="flex w-full flex-col items-center px-2">
            <form
              className="flex flex-col px-10"
              onSubmit={(event) => {
                event.preventDefault();
                const form = event.target;
                const formData = new FormData(form);
                const solvencia = formData.get("solvencia");
                const tipo = formData.get("tipo");
                const dependencia = formData.get("dependencia");
                const nomina = formData.get("nomina");
                const universo = formData.get("universo");
                const fechaIngreso = formData.get("fechaIngreso");
                const dateBirthDay = formData.get("dateBirthDay");
                const montoLiquidar = formData.get("montoLiquidar");
                const genero = formData.get("genero");
                const tipoLey = formData.get("tipoLey");
                const sindicato = formData.get("sindicato");
                const job = formData.get("job");

                if (!solvencia) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa la solvencia",
                  });
                }
                if (!tipo) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa el tipo",
                  });
                }
                if (!dependencia) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa la dependencia",
                  });
                }
                if (!nomina) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa la nomina",
                  });
                }

                if (!universo) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa el universo",
                  });
                }
                if (!fechaIngreso) {
                  return Swal.fire({
                    type: "error",
                    title: "Ingresa la fecha de ingreso",
                  });
                }

                calculateOfertaFake({
                  solvencia,
                  tipo,
                  dependencia,
                  nomina,
                  universo,
                  fechaIngreso,
                  dateBirthDay,
                  montoLiquidar,
                  genero,
                  tipoLey,
                  sindicato,
                  job,
                });
              }}
            >
              <div className="flex flex-col">
                <label htmlFor="">Solvencia</label>
                <input
                  type="text"
                  name="solvencia"
                  id="solvencia"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Tipo</label>
                <select name="tipo" id="tipo" className="rounded-lg p-1">
                  <option value={""}>Selecciona el tipo</option>
                  <option value={"Nuevo"}>Nuevo</option>
                  <option value={"Adicional"}>Adicional</option>
                  <option value={"Refinanciamiento"}>Refinanciamiento</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Dependencia</label>
                <input
                  type="text"
                  name="dependencia"
                  id="dependencia"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Nomina</label>
                <select name="nomina" id="nomina" className="rounded-lg p-1">
                  <option value={""}>Selecciona el tipo de nomina</option>
                  <option value={1}>1</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={8}>8</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Universo</label>
                <input
                  type="text"
                  name="universo"
                  id="universo"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Fecha Ingreso</label>
                <input
                  type="date"
                  name="fechaIngreso"
                  id="fechaIngreso"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Fecha Nacimiento</label>
                <input
                  type="date"
                  name="dateBirthDay"
                  id="dateBirthDay"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Genero</label>
                <select name="genero" id="genero" className="rounded-lg p-1">
                  <option value={""}>Selecciona el genero</option>
                  <option value={"H"}>H</option>
                  <option value={"M"}>M</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Tipo Ley</label>
                <input
                  type="text"
                  name="tipoLey"
                  id="tipoLey"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Sindicato</label>
                <input
                  type="text"
                  name="sindicato"
                  id="sindicato"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Puesto</label>
                <input
                  type="text"
                  name="job"
                  id="job"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Monto a liquidar</label>
                <input
                  type="number"
                  name="montoLiquidar"
                  id="montoLiquidar"
                  className="rounded-lg p-1"
                  defaultValue={0}
                />
              </div>
              <button
                className="mt-10 rounded-[10px] bg-brand-500 p-2 text-white"
                disabled={loading}
              >
                {loading ? "CALCULANDO" : "VER OFERTA"}
              </button>
            </form>
          </div>
          <div className="rounded-[20px] bg-blue-100 py-10">
            <SimuladorC oferta={data} />
          </div>
        </div>
      )}

      {active === 3 && (
        <div className="mt-10 grid grid-cols-[1fr_3fr] grid-rows-[1fr] gap-2">
          <div className="flex  flex-col items-center px-2">
            <form
              className="flex flex-col px-10"
              onSubmit={async (event) => {
                event.preventDefault();
                const formData = new FormData(event.target);
                const data = Object.fromEntries(formData.entries());
                const validation = validateForm(
                  data,
                  formIne[0].requiredFields
                );
                if (!validation.valid) {
                  return Swal.fire({
                    icon: "error",
                    title: validation.message,
                  });
                }
                setLoading(true);
                setXmlStr(null);
                setIneRoot(null);
                const result = await formIne[0].onSubmit(formData);
                if (result) {
                  setXmlStr(result.result);
                  setIneRoot(result.xml);
                }
                setLoading(false);
              }}
            >
              {formIne[0].fields.map((item, index) => {
                return (
                  <div className="flex flex-col" key={index}>
                    <label htmlFor="">{item.label}</label>
                    <input
                      type={item.type}
                      name={item.name}
                      id={item.name}
                      className="rounded-lg p-1"
                    />
                  </div>
                );
              })}

              <button
                className="mt-10 rounded-[10px] bg-brand-500 p-2 text-white"
                disabled={loading}
              >
                {loading ? "VALIDANDO" : "VALIDAR INE"}
              </button>
            </form>
          </div>
          <div
            className={`flex-col items-center justify-center rounded-[20px] bg-blue-100 py-10`}
          >
            <div>
              <span>Respuesta</span>
              {xmlStr && <XMLViewer xml={xmlStr} />}
            </div>
            <div>
              <span>XML ENVIADO</span>

              {ineRoot && <div>{ineRoot}</div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Simulador;
