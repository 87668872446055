import api from "service";

const formIne = [
  {
    index: 3,
    title: "Prueba Fimpe",
    onSubmit: async (data) => {
        const headers = { "Content-Type": "multipart/form-data" };
       return await api.post("/system/prueba-ine", data, headers);
    },
    fields: [
      {
        name: "Nombre",
        label: "Nombre",
        type: "text",
        // options: [
        //   { value: "", label: "Selecciona" },
        //   { value: "1", label: "1" },
        // ],
      },
      { name: "ApellidoPaterno", label: "ApellidoPaterno", type: "text" },
      { name: "ApellidoMaterno", label: "ApellidoMaterno", type: "text" },
      { name: "Ocr", label: "Ocr", type: "text" },
      { name: "AnioRegistro", label: "AnioRegistro", type: "number" },
      { name: "AnioEmision", label: "AnioEmision", type: "number" },
      { name: "ClaveElector", label: "ClaveElector", type: "text" },
      { name: "NumeroEmision", label: "NumeroEmision", type: "number" },
      { name: "Cic", label: "Cic", type: "text" },
      { name: "Curp", label: "Curp", type: "text" },
      { name: "Rostro", label: "Rostro", type: "file" },
    ],
    requiredFields: ["Nombre", "ApellidoPaterno", "ApellidoMaterno"],
  },
];

export const validateForm = (fields, requiredFields) => {
  for (const field of requiredFields) {
    if (!fields[field]) {
      return { valid: false, message: `Ingresa ${field}` };
    }
  }
  return { valid: true };
};

export default formIne;
