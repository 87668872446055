import React from "react";
import DashIcon from "components/icons/DashIcon";
import Solicitudes from "views/admin/solicitudes";
import Users from "views/admin/users";
import Dashboard from "views/admin/default";
import SignIn from "views/auth/SignIn";
import Montos from "views/admin/montos";
import Simulador from "views/admin/simulador";
import Banks from "views/admin/banks";
import Datas from "views/admin/datas";
import Logs from "views/admin/logs";
import Audit from "views/admin/audit";

import { FiCreditCard } from "react-icons/fi";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { FiShieldOff } from "react-icons/fi";
import { SiCashapp } from "react-icons/si";
import { FaTruckLoading } from "react-icons/fa";
import { FaUsersCog } from "react-icons/fa";
import { FaPiggyBank } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa";
import { GiWoodenClogs } from "react-icons/gi";
import { FaSignLanguage } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import SolicitudDetail from "views/admin/solicitud-detalles";
import Pagares from "views/admin/report/pagare";
import SearchFimpe from "views/admin/report/search-fimpe";
import AcuseCep from "views/admin/report/acuse-cep";
import AsesoresExternos from "views/admin/report/asesores_externos";
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    component: <Dashboard />,
    icon: <DashIcon />,
    associateBd: 1,
  },
  {
    name: "Solicitudes crédito",
    layout: "/admin",
    path: "solcitudes", // Sin barra inicial
    secondary: true,
    associateBd: 2,
    icon: <FiCreditCard />,
    children: [
      {
        name: "GCDMX",
        layout: "/admin",
        path: "gcdmx", // Sin barra inicial
        icon: "",
        secondary: true,
        associateBd: 3,
        component: (
          <Solicitudes
            convenio={"GCDMX"}
            status={"autorizada,solicitada,proceso,devolucion-email"}
            module={"GCDMX"}
          />
        ),
        urlFull: "/admin/solcitudes/gcdmx",
      },
      {
        name: "Detalle",
        layout: "/admin",
        path: "gcdmx/details", // Sin barra inicial
        icon: "",
        secondary: true,
        associateBd: 3,
        noMenu: true,
        component: <SolicitudDetail convenio={"GCDMX"} module={"GCDMX"} />,
        urlFull: "/admin/solcitudes/gcdmx/details",
      },
    ],
  },
  {
    name: "Por atender",
    layout: "/admin",
    path: "por-atender", // Sin barra inicial
    component: <Solicitudes status="por-atender" module={"Por atender"} />,
    associateBd: 8,
    icon: <FiUsers />,
  },
  {
    name: "Devoluciones",
    layout: "/admin",
    path: "devoluciones", // Sin barra inicial
    component: <Solicitudes status="Devuelta" module={"Devoluciones"} />,
    associateBd: 4,
    icon: <FiArrowLeftCircle />,
  },
  {
    name: "Reasignación",
    layout: "/admin",
    path: "reasignacion", // Sin barra inicial
    component: <Solicitudes status="reasignacion" module={"Reasignar"} />,
    associateBd: 7,
    icon: <FaSignLanguage />,
  },

  {
    name: "Canceladas",
    layout: "/admin",
    path: "canceladas", // Sin barra inicial
    component: <Solicitudes status="cancelada" module={"Canceladas"} />,
    associateBd: 10,
    icon: <FiShieldOff />,
  },
  {
    name: "Reportes",
    layout: "/admin",
    path: "report", // Sin barra inicial
    secondary: true,
    associateBd: 21,
    icon: <TbReportAnalytics />,
    children: [
      {
        name: "Pagare",
        layout: "/admin",
        path: "pagare", // Sin barra inicial
        icon: "",
        secondary: true,
        associateBd: 22,
        component: <Pagares module={"REPORT-PAGARE"} />,
        urlFull: "/admin/report/pagare",
      },
      {
        name: "Fimpe-Curp",
        layout: "/admin",
        path: "fimpe-curp",
        icon: "",
        secondary: true,
        associateBd: 23,
        component: <SearchFimpe module={"REPORT-PAGARE"} />,
        urlFull: "/admin/report/fimpe-curp",
      },
      {
        name: "Acuse cep",
        layout: "/admin",
        path: "acuse-cep",
        icon: "",
        secondary: true,
        associateBd: 24,
        component: <AcuseCep module={"REPORT-PAGARE"} />,
        urlFull: "/admin/report/acuse-cep",
      },
      {
        name: "Asesores externos",
        layout: "/admin",
        path: "asesores-externos",
        icon: "",
        secondary: true,
        associateBd: 25,
        component: <AsesoresExternos module={"REPORT-PAGARE"} />,
        urlFull: "/admin/report/asesores-externos",
      },
    ],
  },
  {
    name: "Montos",
    layout: "/admin",
    path: "montos", // Sin barra inicial
    component: <Montos />,
    associateBd: 12,
    icon: <SiCashapp />,
  },
  {
    name: "Simulador",
    layout: "/admin",
    path: "simulador", // Sin barra inicial
    component: <Simulador />,
    associateBd: 13,
    icon: <FaTruckLoading />,
  },
  {
    name: "Usuarios",
    layout: "/admin",
    path: "users", // Sin barra inicial
    component: <Users />,
    associateBd: 5,
    icon: <FaUsersCog />,
  },
  {
    name: "Bancos",
    layout: "/admin",
    path: "banks", // Sin barra inicial
    component: <Banks />,
    associateBd: 14,
    icon: <FaPiggyBank />,
  },
  {
    name: "Configuración",
    layout: "/admin",
    path: "datas", // Sin barra inicial
    component: <Datas />,
    associateBd: 15,
    icon: <FaDatabase />,
  },
  {
    name: "Log Sys",
    layout: "/admin",
    path: "logs", // Sin barra inicial
    component: <Logs />,
    associateBd: 16,
    icon: <GiWoodenClogs />,
  },
  {
    name: "Auditoria",
    layout: "/admin",
    path: "audi", // Sin barra inicial
    component: <Audit />,
    associateBd: 20,
    icon: <GiWoodenClogs />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in", // Sin barra inicial
    component: <SignIn />,
    notBar: true,
  },
];

export default routes;
