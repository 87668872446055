import { useEffect, useState } from "react";
import { X } from "lucide-react";
import FileUpload from "./file-upload";
import ConfigCard from "./config-card";
import api from "service";
import Grid from "components/grid";

const Modal = ({ isOpen, onClose, title, children }) => {
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-2xl font-semibold">{title}</h2>
          <button
            onClick={onClose}
            className="rounded-full p-1 hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const Datas = () => {
  const [activeModal, setActiveModal] = useState();
  const [data, setData] = useState([]);

  const handleViewList = (listType) => {
    setActiveModal(`view-${listType}`);

    const params = {
      periods: "",
      blacklist: "blacklist",
    };
    let endpoint = `system/black-list?type=${params[listType] ?? "producto"}`;
    if (listType === "periods") {
      endpoint = `system/periodos`;
    }
    api
      .get(endpoint)
      .then((res) => {
        setData(res);
      })
      .finally(() => {});
  };

  const handleUpload = (uploadType) => {
    setActiveModal(`upload-${uploadType}`);
  };

  const getType = () => {
    return activeModal?.split("-")[1] === "periods"
      ? "periodos"
      : activeModal?.split("-")[1] === "blacklist"
      ? "blacklist"
      : "producto";
  };

  const getColumns = () => {
    const views = activeModal?.split("-")[1];

    if (views === "periods") {
      return [
        { name: "Inicio", field: "start" },
        { name: "Fin", field: "end" },
        { name: "Fecha pago", field: "payment" },
      ];
    }
    if (views === "blacklist") {
      return [
        { name: "Nombre", field: "name" },
        { name: "CURP", field: "curp" },
      ];
    }
    return [
      { name: "Nombre", field: "name" },
      { name: "CURP", field: "curp" },
      { name: "PRODUCTO", field: "product" },
    ];
  };

  return (
    <div className="min-h-screen px-2 py-10">
      <div className="mx-auto max-w-7xl">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          <ConfigCard
            title="Periodos"
            onViewList={() => handleViewList("periods")}
            onUpload={() => handleUpload("periods")}
          />
          <ConfigCard
            title="Lista Negra"
            onViewList={() => handleViewList("blacklist")}
            onUpload={() => handleUpload("blacklist")}
          />
          <ConfigCard
            title="Lista Negra Productos"
            onViewList={() => handleViewList("products")}
            onUpload={() => handleUpload("products")}
          />
        </div>

        <Modal
          isOpen={activeModal?.startsWith("view-")}
          onClose={() => setActiveModal(null)}
          title={`${
            activeModal?.split("-")[1] === "periods"
              ? "Periodos"
              : activeModal?.split("-")[1] === "blacklist"
              ? "Lista Negra"
              : "Lista Negra Productos"
          }`}
        >
          <div className="space-y-4">
            <p className="text-gray-600">
              {activeModal?.split("-")[1] === "periods"
                ? "Lista de periodos disponibles"
                : activeModal?.split("-")[1] === "blacklist"
                ? "Lista negra de usuarios"
                : "Lista negra de productos"}
            </p>
            {data && data.length > 0 ? (
              <Grid colums={getColumns()} data={data} />
            ) : (
              <div className="rounded-lg bg-gray-50 p-4 text-center text-gray-500">
                No hay elementos para mostrar
              </div>
            )}
          </div>
        </Modal>

        <Modal
          isOpen={activeModal?.startsWith("upload-")}
          onClose={() => setActiveModal(null)}
          title={`Subir ${
            activeModal?.split("-")[1] === "periods"
              ? "Periodos"
              : activeModal?.split("-")[1] === "blacklist"
              ? "Lista Negra"
              : "Lista Negra Productos"
          }`}
        >
          <FileUpload
            handleClose={() => {
              setActiveModal();
            }}
            type={getType()}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Datas;
