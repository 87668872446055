import Filter from "components/filter";
import Grid from "components/grid";
import { format, parseISO } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";

const Audit = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [auditLog, setAuditLog] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [textSolicitud, setTextSolicitud] = useState("");

  const [formValues, setFormValues] = useState({});

  const { REACT_APP_URL_BASE } = process.env;

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    if (id > 0) {
      setFormValues((old) => {
        return { ...old, id };
      });
      handleSearch();
    }
  }, [id]);
  const handleSearch = useCallback(async () => {
    setLoading(true);
    const headersList = {
      Authorization: `Bearer ${token}`,
    };

    const $id = formValues?.id ? formValues.id : id;
    const endpoint = `${REACT_APP_URL_BASE}/system/audit?id=${
      $id ?? 0
    }&accion=${formValues.accion ?? ""}&user=${formValues.user ?? ""}`;
    fetch(endpoint, {
      method: "GET",
      headers: headersList,
    })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setAuditLog(json);
        } else {
          setAuditLog([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, textSolicitud, formValues]);

  useEffect(() => {
    if (id === null || id === undefined) {
      const idRoles = [1, 4, 5, 6, 7, 8];
      const queryString = idRoles.map((id) => `idRole=${id}`).join("&");
      fetch(`${REACT_APP_URL_BASE}/users?${queryString}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((allUsers) => {
          setAllUsers(allUsers);
        });
    }
  }, [token, id]);

  return (
    <div className="mt-5 grid h-full grid-cols-1 ">
      <div className="flex flex-row items-center justify-start duration-300">
        {!id && (
          <>
            <input
              type="text"
              value={formValues.id}
              onChange={(e) =>
                setFormValues((old) => {
                  return { ...old, id: e.target.value };
                })
              }
              className="ml-3 mr-2 h-[40px] w-[100px] rounded-md border border-gray-300 px-2"
              placeholder="Id Solicitud"
            />
            {!formValues.id && (
              <>
                <select
                  className="ml-3 mr-2 h-[40px] rounded-md border border-gray-300 px-2"
                  onChange={(e) =>
                    setFormValues((old) => {
                      return { ...old, accion: e.target.value };
                    })
                  }
                >
                  <option value="">Filtro por acción</option>
                  <option value="sesion">Inicio de sesion</option>
                  <option value="accesso">Acceso a modulos</option>
                </select>
                {formValues.accion && (
                  <select
                    className="ml-3 mr-2 h-[40px] rounded-md border border-gray-300 px-2"
                    onChange={(e) =>
                      setFormValues((old) => {
                        return { ...old, user: e.target.value };
                      })
                    }
                  >
                    <option value="">Selecciona el usuario</option>
                    {allUsers &&
                      allUsers.map((item) => {
                        return <option value={item.email}>{item.email}</option>;
                      })}
                  </select>
                )}
              </>
            )}

            <button
              onClick={handleSearch}
              className="hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mr-5 w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
            >
              Filtrar
            </button>
          </>
        )}
      </div>
      <Filter
        showFilterText={true}
        setTextSearch={setTextSearch}
        textSearch={textSearch}
        // handleFilter={id ? null : handleSearch}
      />
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        auditLog && (
          <div className="h-[500px] overflow-y-scroll">
            <Grid
              colums={
                id
                  ? [
                      { name: "NOMBRE" },
                      { name: "CORREO" },
                      { name: "MODULO" },
                      { name: "ACCIÓN" },
                      { name: "FECHA Y HORA" },
                    ]
                  : [
                      { name: "NOMBRE" },
                      { name: "CORREO" },
                      { name: "MODULO" },
                      { name: "ACCIÓN" },
                      { name: "SOLICITUD" },
                      { name: "FECHA Y HORA" },
                    ]
              }
              data={auditLog ? auditLog : []}
              textFilter={textSearch}
              keysFilter={["accion"]}
              itemsPerPageProps={50}
              ComponentRow={({ item }) => {
                return (
                  <>
                    <div className="table-cell p-2">{item?.user}</div>
                    <div className="table-cell p-2">{item?.email}</div>
                    <div className="table-cell p-2">{item.module}</div>
                    <div className="table-cell p-2">{item.accion}</div>
                    {!id && (
                      <div className="table-cell p-2">{item.solicitudId}</div>
                    )}
                    <div className="table-cell p-2">
                      {format(parseISO(item.createdAt), "yyyy-MM-dd HH:mm")}
                    </div>
                  </>
                );
              }}
            />
          </div>
        )
      )}
    </div>
  );
};

export default Audit;
