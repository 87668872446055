export default function TextInfo({ label, value, classContainer, isCopy }) {
  return (
    <div className={`flex flex-col ${classContainer} px-2`}>
      <span className="uppercase text-[12px]">{label}</span>
      <strong className="uppercase text-[12px]" onClick={()=> {
        if(isCopy) {
          navigator.clipboard.writeText(value);
        }
      }}>{value}</strong>
    </div>
  );
}
