import { useState } from "react";
import Filter from "components/filter";
import api from "service";
import Grid from "components/grid";

const AsesoresExternos = ({ module }) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleFilter = () => {
    setLoading(true);
    return api
      .get(`/report/asesores-externos`)
      .then((res) => {
        if (res) setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="mt-5 grid h-full grid-cols-1 ">
      <Filter
        handleFilter={handleFilter}
        exportColumns={[
          { field: "name", headerName: "NOMBRE" },
          { field: "lastName", headerName: "APELLIDOS" },
          { field: "email", headerName: "CORREO" },
          { field: "tipo", headerName: "TIPO" },
          { field: "FechaSolicitud", headerName: "FECHA SOLITUD" },
        ]}
        dataExport={data}
      />

      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <Grid
          colums={[
            { name: "Nombre", field: "name" },
            { name: "Apellidos", field: "lastName" },
            { name: "Correo", field: "email" },
            { name: "Tipo", field: "tipo" },
            { name: "Fecha Solicitud", field: "fechaSolicitud" },
          ]}
          data={data ? data : []}
        />
      )}
    </div>
  );
};

export default AsesoresExternos;
