import ModalGeneral from "components/modal";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import api from "service";
import Swal from "sweetalert2";

const ItemRow = ({ label, value }) => {
  return (
    <div className="grid-cols-[1fr, auto] grid gap-2 border-b border-gray-100 py-3 text-sm">
      <span className="data-label">{label}</span>
      {label === "UrlCep" ? (
        <a href={value} target="_blank">
          {" "}
          Ver acuse
        </a>
      ) : (
        <span className="data-value">{value}</span>
      )}
    </div>
  );
};

const AcuseCep = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [accountDetails, setAccountDetails] = useState({});
  const [consultations, setConsultations] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBanks();
  }, []);

  const handleFilter = () => {
    if (!accountNumber) {
      return Swal.fire({
        type: "error",
        title: "Aviso",
        text: "Ingresa el número de cuenta",
      });
    }
    setLoading(true);
    api
      .get(`report/acuse-cep?account=${accountNumber}`)
      .then((res) => {
        if (res) {
          setLoading(false);
          setAccountDetails(res.acuse);
          setConsultations(res.history);
        } else {
          return Swal.fire({
            text: "No se encontraron resultados con la cuenta clabe",
            title: "Aviso",
          });
        }
      })
      
  };

  const getBanks = () => {
    api.get("system/banks").then((res) => {
      if (res) {
        setBanks(res);
      }
    });
  };

  const validateForm = (data) => {
    const validationRules = {
      name: {
        condition: (value) => !value,
        message: "Ingresa el nombre del cliente",
        title: "Datos faltantes",
      },

      account: {
        condition: (value) => !value || value.length !== 18,
        message: "Ingresa la cuenta clabe a 18 digitos",
        title: "Datos faltantes",
      },
      bank: {
        condition: (value) => !value,
        message: "Selecciona el banco perteneciente a la cuenta clabe",
        title: "Datos faltantes",
      },
    };

    // Recorremos las reglas de validación
    for (const field in validationRules) {
      const { condition, message, title } = validationRules[field];
      if (condition(data[field])) {
        return Swal.fire({
          type: "error",
          title: title,
          text: message,
        });
      }
    }
    return true;
  };

  const handlePost = (form) => {
    const headers = { username: "glre", password: "5HPk5CR9ENyd8yZ" };
    api.post("/ServiceStp/validate-clabe", form, headers).then((res) => {
      if (res && res.resultado) {
        const { id, descripcionError } = res.resultado;
        if (descripcionError && id) {
          return Swal.fire({
            type: "error",
            title: "Aviso",
            text: res.resultado.descripcionError,
          });
        } else if (!descripcionError) {
          setOpenModal(false);
          return Swal.fire({
            icon: "success",
            title: "Aviso",
            text: "La diseperción se mando correctamente. En espera de resultado",
          });
        }
      }
    });
  };
  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="mx-auto ">
        <div className="grid grid-cols-2 gap-6">
          <div className="rounded-xl bg-white p-6 shadow-sm">
            <h1 className="mb-6 text-2xl font-semibold text-gray-900">
              Consultar acuses CEP
            </h1>

            <div className="mb-8 flex gap-4">
              <div className="flex-1">
                <div className="relative">
                  <input
                    type="text"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    placeholder="Ingrese cuenta clabe"
                    className="inputBase w-full pr-12"
                  />
                  <IoMdSearch className="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
                </div>
              </div>
              <button
                className="buttonBase bg-blue-600 text-white hover:bg-blue-700"
                onClick={handleFilter}
              >
                Consultar
              </button>
              <button
                className="buttonBase bg-blue-600 text-white hover:bg-blue-700"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Generar acuse
              </button>
            </div>

            <div className="space-y-2">
              {loading ? (
                "Cargando información"
              ) : (
                <>
                  <ItemRow label="RfcCep" value={accountDetails?.rfcCep} />
                  <ItemRow label="UrlCep" value={accountDetails?.urlCEP} />
                  <ItemRow
                    label="Nombre cep"
                    value={accountDetails?.nombreCep}
                  />
                  <ItemRow label="Empresa" value={accountDetails?.empresa} />
                  <ItemRow
                    label="Fecha Operacion"
                    value={accountDetails?.fechaOperacion}
                  />
                  <ItemRow
                    label="Clave rastreo"
                    value={accountDetails?.claveRastreo}
                  />
                  <ItemRow
                    label="Cuenta Beneficiario"
                    value={accountDetails?.cuentaBeneficiario}
                  />{" "}
                </>
              )}
            </div>
          </div>

          <div className="rounded-xl bg-white p-6 shadow-sm">
            <div className="mb-6 flex items-center justify-between">
              <h2 className="text-lg font-semibold text-gray-900">
                Registro de consultas
              </h2>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="w-40"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="w-40"
                  />
                </div>
                <button className="buttonBase bg-gray-100 text-gray-700 hover:bg-gray-200">
                  Filtrar
                </button>
              </div>
            </div>

            <div className="mb-4 text-sm text-gray-500">
              Total: {consultations && consultations.length}
            </div>

            <div className="space-y-1">
              {consultations &&
                consultations.map((consultation, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-2 gap-4 border-b border-gray-100 py-4 text-sm transition-colors hover:bg-gray-50"
                  >
                    <span>Consulta a cuenta {consultation.account}</span>
                    <span className="text-gray-500">
                      {format(
                        parseISO(consultation.createdAt),
                        "dd MMMM yyyy hh:mm"
                      )}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalGeneral
          title="Genera acuse"
          close={() => {
            setOpenModal(false);
          }}
        >
          <form
            className="px-4 py-4"
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.target);
              const data = Object.fromEntries(formData.entries());
              const result = validateForm(data);
              if (result !== true) return;

              Swal.fire({
                type: "confirm",
                text: "Se dispersara 1 centavo a los datos ingresados. ¿Continuar?",
                title: "Confirmación",
                cancelButtonText: "No",
                confirmButtonText: "Si",
                showCancelButton: true,
                icon: "warning",
              }).then(async (res) => {
                if (res.isConfirmed) {
                  const button = document.getElementById("submitButton");
                  button.disabled = true;
                  button.innerHTML = "Cargando...";
                  await handlePost({
                    cuentaBeneficiario: data.account,
                    institucionContraparte: data.bank,
                    nombreBeneficiario: data.name,
                  });
                }
              });
            }}
          >
            <div className="flex flex-col">
              <label htmlFor="name">Nombre cliente</label>
              <input
                type="text"
                placeholder="Ingrese el nombre del cliente"
                className="inputBase w-full pr-12"
                name="name"
                id="name"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="account">Cuenta clabe</label>
              <input
                type="number"
                placeholder="Ingrese cuenta clabe"
                className="inputBase w-full pr-12"
                name="account"
                id="account"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="account">Banco</label>
              <select className="inputBase w-full pr-12" name="bank" id="bank">
                <option value={""}>Selecciona una banco</option>

                {banks &&
                  banks.map((item) => {
                    return <option value={item.codeISO}>{item.name}</option>;
                  })}
              </select>
            </div>
            <div className="flex flex-row justify-end py-5">
              <button
                className="buttonBase bg-blue-600 text-white hover:bg-blue-700"
                id="submitButton"
                type="submit"
              >
                Generar
              </button>
            </div>
          </form>
        </ModalGeneral>
      )}
    </div>
  );
};

export default AcuseCep;
