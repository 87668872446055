import { useMemo, useState } from "react";
import Grid from "components/grid";
import { useEffect } from "react";
import { NumberMxn } from "helpers";
import Filter from "components/filter";

const Montos = () => {
  const [active, setActive] = useState(36);
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [data, setData] = useState();

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    if (active) getAll();
  }, [active]);

  const getClassActive = () => " rounded-tl-lg  bg-brand-400 text-white";

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/system/plazos?plazo=${active}`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setData(json);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <div className="flex">
          {[48, 60, 72, 84, 96, 108, 120].map((item) => {
            return (
              <button
                className={`px-4 py-2  ${
                  active === item ? getClassActive() : "bg-gray-200"
                }`}
                onClick={() => setActive(item)}
              >
                Plazo {item}
              </button>
            );
          })}
        </div>

        <Filter
          showFilterText={true}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
        />
        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          data && (
            <Grid
              colums={[
                { name: "Plazo" },
                { name: "Pago quincenal" },
                { name: "Monto prestamo" },
                { name: "Pago total" },
                { name: "Clasificación" },
              ]}
              data={data ? data : []}
              textFilter={textSearch}
              keysFilter={["plazo", "monto", "total", "payment"]}
              ComponentRow={({ item }) => {
                return (
                  <>
                    <div className="table-cell p-2">{item.plazo}</div>
                    <div className="table-cell p-2">
                      {NumberMxn(item.monto)}
                    </div>
                    <div className="table-cell p-2">
                      {NumberMxn(item.total)}
                    </div>
                    <div className="table-cell p-2">
                      {NumberMxn(item.payment)}
                    </div>
                    <div className="table-cell p-2">{item.clasificacion}</div>
                  </>
                );
              }}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Montos;
