import { useEffect, useMemo, useState } from "react";
import useLogTracker from "hooks/useLogTracker";
import api from "../../../service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";
import InputField from "components/fields/InputField";
import Magnifier from "react-magnifier";
import { IoMdCloseCircle } from "react-icons/io";
import { FaLongArrowAltLeft } from "react-icons/fa";

import Swal from "sweetalert2";
import {
  arrrayDos,
  documentsArray,
  inputListAddress,
  inputsList,
} from "./types";
import { Observaciones } from "../solicitudes/obrservaciones";
import { useSelector } from "react-redux";
import ItemImage from "./components/item-image";

const SolicitudDetail = ({ module }) => {
  const { registerCustomEvent } = useLogTracker(module);
  const { REACT_APP_URL_BASE } = process.env;
  const navigate = useNavigate();
  const { token, rol } = useSelector((state) => state.auth);

  const [searchParams] = useSearchParams();
  const info = searchParams.get("query");
  const moduleOrigin = searchParams.get("moduleOrigin");

  const [solicitud, setSolicitud] = useState({});
  const [general, setGeneral] = useState();
  const [address, setAddress] = useState();
  const [comentarios, setComentarios] = useState(false);
  const [modalComentarios, setModalComentarios] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const solicitudUrl = useMemo(() => {
    if (info) {
      const decodedString = Buffer.from(info, "base64").toString("utf-8");
      const jsonObject = JSON.parse(decodedString);
      return jsonObject;
    }
  }, [info]);

  const id = useMemo(() => {
    return solicitudUrl.id;
  }, [moduleOrigin]);

  const isDevoluciones = useMemo(() => {
    if (moduleOrigin) return moduleOrigin.includes("/admin/devoluciones");
  }, [moduleOrigin]);

  const isAtender = useMemo(() => {
    if (moduleOrigin) return moduleOrigin.includes("/admin/por-atender");
  }, [moduleOrigin]);

  useEffect(() => {
    if (id) {
      getSolicitudById();
    }
  }, [id]);

  useEffect(() => {
    if (solicitud?.general) setGeneral(solicitud?.general ?? {});
    if (solicitud?.address) setAddress(solicitud?.address ?? {});
  }, [solicitud]);

  const documentos = useMemo(() => {
    return solicitud?.documents ?? [];
  }, [solicitud]);

  const account = useMemo(() => {
    return solicitud?.account ?? {};
  }, [solicitud]);

  const isComment = useMemo(() => {
    if (!documentos) return false;
    const comentarioExiste = documentos.some((doc) => doc.comments);
    return comentarioExiste;
  }, [documentos]);

  const getSolicitudById = () => {
    const endpoint = `/solicitudes/${id}`;
    api.get(endpoint).then((json) => {
      if (json) {
        setSolicitud(json);
      }
    });
  };

  const OpenModalComentarios = (document) => {
    if (document) {
      setComentarios(document);
      setModalComentarios(true);
    }
  };

  const CloseModalComentarios = () => {
    setModalComentarios(false);
    getSolicitudById();
  };

  const handleDevolverClick = (status) => {
    registerCustomEvent("Click en devolver / Validar datos", {
      solicitudId: id,
    });
    Swal.fire({
      title: status
        ? "Por favor confirma el cambio de estatus de la solicitud"
        : "Por favor confirma la devolución de solicitud.",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const endpoint = `/solicitudes/${general.id}/change-status`;
        api
          .put(endpoint, { textStatus: status ? status : "Devuelta" })
          .finally(() => {});
      }
    });
  };

  const validateFields = () => {
    const allGeneralFieldsFilled = inputsList.every(
      (item) => general && general[item.prop]
    );
    const allAddressFieldsFilled = inputListAddress.every(
      (item) => address && address[item.prop]
    );

    return allGeneralFieldsFilled && allAddressFieldsFilled;
  };

  const buildDto = () => {
    return {
      CodigoPostal: address.codigoPostal,
      Calle: address.calle,
      NExterior: address.nExterior,
      NInterior: address.nInterior,
      Ciudad: address.ciudad,
      Estado: address.estado,
      Delegacion: address.delegacion,
      Colonia: address.colonia,
    };
  };

  const handleSave = () => {
    if (!validateFields()) {
      Swal.fire({
        title: "Por favor rellena todos los campos de datos.",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    registerCustomEvent("Click en guardar / Validar datos", {
      solicitudId: id,
    });

    Swal.fire({
      title: "¿Desea guardar los cambios?",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const optionsUpdate = {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            module,
          },
          body: JSON.stringify(general),
        };

        const updateEndpoint = `${REACT_APP_URL_BASE}/solicitudes/${general.id}`;
        const updateRequest = fetch(updateEndpoint, optionsUpdate).then((res) =>
          res.json()
        );
        const requests = [updateRequest];
        const body = buildDto();
        const addressEndpoint = `${REACT_APP_URL_BASE}/solicitudes/${general.id}/direccion`;
        const optionsAddress = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            module,
          },
          body: JSON.stringify(body),
        };
        const addressRequest = fetch(addressEndpoint, optionsAddress).then(
          (res) => res.json()
        );
        requests.push(addressRequest);

        Promise.all(requests)
          .then((responses) => {
            getSolicitudById();
            Swal.fire({
              title: "Cambios guardados exitosamente.",
              icon: "success",
              confirmButtonText: "Aceptar",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error al guardar los cambios.",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });
      }
    });
  };

  const renderBtnDevolver = () => {
    if (!general) return null;
    if (general?.estatus === "autorizada") return null;
    if (isDevoluciones) return null;
    if (!isComment) return null;
    if ([1, 4, 6, 7].includes(rol)) {
      return (
        <button
          onClick={() => handleDevolverClick()}
          className="linear hover:bg-brand-500-hover active:bg-brand-500-hover  mt-2 mt-[80px] w-[200px] rounded-xl bg-brand-500  py-[12px] text-base font-medium text-white transition transition-all duration-200 duration-300"
        >
          Devolver
        </button>
      );
    }
  };

  const renderBtnSave = () => {
    if (!general) return null;
    if (general?.estatus === "autorizada") return null;
    if (general?.documentId) return null;
    if ([1, 4, 6, 7].includes(rol)) {
      return (
        <button
          className="sticky bottom-2 right-0 ml-[450px] w-48 rounded-xl bg-gray-600 py-3 font-medium text-white transition-all duration-200 hover:bg-gray-700 active:bg-gray-800"
          onClick={handleSave}
        >
          Guardar cambios
        </button>
      );
    }
  };

  const renderEnviarProceso = () => {
    if (isAtender && !isComment) {
      return (
        <button
          onClick={() => handleDevolverClick("proceso")}
          className="linear hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover  dark:active:bg-brand-500-hover mt-2 mt-[80px] w-[200px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition transition-all duration-200 duration-300 dark:bg-brand-500 dark:text-white"
        >
          Enviar a proceso
        </button>
      );
    }
  };
  return (
    <div className="mt-5 grid h-full grid-cols-1 bg-white">
      <div className="flex flex-row items-center justify-between gap-2 px-2 py-2">
        <h1 className="text-lg font-bold">SOLICITUD ID {id}</h1>

        <button
          onClick={() => {
            navigate(-1);
          }}
          className={`gtext-brand-500 ${selectedImage ? "hidden" : ""}`}
        >
          <IoMdCloseCircle size={34} />
        </button>
      </div>
      <div className="grid gap-8  md:grid-cols-2">
        <div className="relative flex h-[700px] flex-col overflow-y-scroll px-2">
          {inputsList.map((item, index) => {
            return (
              <InputField
                key={`general-${index}`}
                extra="mb-3"
                label={item.label}
                placeholder=""
                id={item.prop}
                type={item.type}
                value={general?.[item.prop]}
                onChange={(e) => {
                  const value = e.target.value;
                  setGeneral((old) => ({
                    ...old,
                    [item.prop]: value,
                  }));
                }}
              />
            );
          })}
          {inputListAddress.map((item, index) => {
            return (
              <InputField
                key={`address-${index}`}
                extra="mb-3"
                label={item.label}
                placeholder=""
                id={item.prop}
                type={item.type}
                value={address?.[item.prop]}
                onChange={(e) => {
                  const value = e.target.value;
                  setAddress((old) => {
                    return { ...old, [item.prop]: value };
                  });
                }}
              />
            );
          })}
          {arrrayDos.map((item, index) => {
            return (
              <InputField
                key={`general-${index}`}
                extra="mb-3"
                label={item.label}
                placeholder=""
                id={item.prop}
                type={item.type}
                value={general?.[item.prop]}
                onChange={(e) => {
                  const value = e.target.value;
                  setGeneral((old) => ({
                    ...old,
                    [item.prop]: value,
                  }));
                }}
              />
            );
          })}
          <InputField
            key={`general-99`}
            extra="mb-3"
            label={"Cuenta clabe"}
            placeholder=""
            id={"cuenta-clave"}
            type={"text"}
            value={account ? account.account : ""}
            disabled={true}
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div
            className={`grid grid-cols-2 gap-8  px-2 ${
              selectedImage ? "hidden" : ""
            }`}
          >
            {documentsArray.map((image) => {
              const document = documentos.find(
                (doc) => doc.type === image.type
              );
              return (
                <ItemImage
                  document={document}
                  image={image}
                  OpenModalComentarios={OpenModalComentarios}
                  setSelectedImage={setSelectedImage}
                />
              );
            })}
          </div>
          <div
            className={`flex w-full flex-col items-end justify-end  gap-2  ${
              selectedImage ? "hidden" : ""
            }`}
          >
            {renderBtnDevolver()}
            {renderEnviarProceso()}
            {renderBtnSave()}
          </div>
        </div>
        {selectedImage && (
          <div className="absolute right-[60px] top-[80px] z-[999] grid  place-items-center">
            <div className="absolute right-[-10px] top-0 z-[9999] flex w-full justify-end px-2">
              <button
                onClick={() => {
                  setSelectedImage(null);
                }}
                className="text-brand-500"
              >
                <IoMdCloseCircle size={34} />
              </button>
            </div>
            <Magnifier
              src={selectedImage?.file}
              zoomFactor={0.5}
              mgHeight={350}
              mgWidth={450}
              mgShape="square"
              width={600}
            />
          </div>
        )}
      </div>
      {modalComentarios && (
        <Observaciones
          close={CloseModalComentarios}
          token={""}
          title={`Comentarios ${comentarios?.title}`}
          boton="Guardar Comentarios"
          document={comentarios}
          defaultText={comentarios?.comments}
          rol={rol}
          isDevoluciones={isAtender}
          module={module}
        />
      )}
    </div>
  );
};

export default SolicitudDetail;
