import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Badge from "./Badge";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";

export function SidebarLinks(props) {
  const location = useLocation();
  const {
    countSolicitadas,
    countDevueltas,
    countAtender,
    countCanceladas,
    countReasignar,
    countProceso,
  } = useSelector((state) => state.auth);

  const { routes, permissions } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const notificaciones = useMemo(() => {
    return {
      4: countDevueltas,
      3: (countProceso ?? 0) + (countSolicitadas ?? 0),
      8: countAtender ?? 0,
    };
  }, [
    countSolicitadas,
    countDevueltas,
    countAtender,
    countCanceladas,
    countReasignar,
    countProceso,
  ]);

  const [collapsedMenus, setCollapsedMenus] = useState({});

  const toggleMenu = (routeName) => {
    setCollapsedMenus((prev) => ({
      ...prev,
      [routeName]: !prev[routeName],
    }));
  };

  const createLinks = (routes) => {
    return routes
      .filter((item) => permissions.includes(item.associateBd) && !item.noMenu)
      .map((route, index) => {
        if (route.layout === "/admin" || route.layout === "/rtl") {
          const isActive = activeRoute(route.path);
          const isCollapsed = collapsedMenus[route.name];
          const linkClass = isActive
            ? "font-bold text-brand-500 dark:text-white"
            : "text-[16px] text-gray-600";
          const textClass = isActive
            ? "font-bold text-navy-700 dark:text-white"
            : "text-[16px] text-gray-600";
          const indicatorClass = isActive
            ? "absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400"
            : "";

          return (
            <React.Fragment key={route.name}>
              <li className="my-[3px] flex cursor-pointer items-center px-8">
                <div className="relative mb-3 flex w-full items-center">
                  {route.children && (
                    <button
                      className="mr-2"
                      onClick={() => toggleMenu(route.name)}
                    >
                      {isCollapsed ? (
                        <IoIosArrowDropdownCircle />
                      ) : (
                        <IoIosArrowDroprightCircle />
                      )}
                    </button>
                  )}
                  <Link
                    to={
                      route.urlFull
                        ? route.urlFull
                        : route.layout +
                          (route.path.startsWith("/")
                            ? route.path
                            : "/" + route.path)
                    }
                    className="flex w-full items-center hover:cursor-pointer"
                  >
                    {route.children ? null : (
                      <span className={`${linkClass} mr-4`}>
                        {route.icon ? route.icon : null}
                      </span>
                    )}
                    <p className={`leading-1 flex ${textClass}`}>
                      {route.name}
                    </p>
                    {notificaciones.hasOwnProperty(route.associateBd) ? (
                      <Badge value={notificaciones[route.associateBd]} />
                    ) : null}
                  </Link>
                </div>
              </li>

              {/* Recursividad para mostrar los submenús */}
              {route.children && isCollapsed && (
                <ul className="ml-6 transition-all duration-300 ease-in-out">
                  {createLinks(route.children)}
                </ul>
              )}
            </React.Fragment>
          );
        }
        return null;
      });
  };

  return <ul>{createLinks(routes)}</ul>;
}

export default SidebarLinks;
