import { useMemo, useState } from "react";
import MesaControl from "./types/mesa-control";
import AsesoresUsers from "./types/asesores";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const Users = () => {
  const [active, setActive] = useState(1);

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  const getClassActive = () => " rounded-tl-lg  bg-brand-400 text-white";

  const handleChangeStatus = async ({ id, status, isDelete }) => {
    const title = !status
      ? "¿Se desactivará al usuario?"
      : "¿Se activara al usuario?";
    const text = !status
      ? isDelete
        ? "Al eliminar el usuario este no podrá realizar solicitudes y acceder a la app"
        : "Al desactivar al usuario no podrá acceder a la aplicación"
      : "Al activar al usuario este podrá acceder a la aplicación y solicitar creditos";

    const endpoint = isDelete ? "delete" : !status ? "disable" : "active";
    const urlBase = `${process.env.REACT_APP_URL_BASE}/users/${id}/${endpoint}`;

    const result = await Swal.fire({
      title,
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(urlBase, {
          method: isDelete ? "DELETE" : "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        if (response.ok) {
          toast.success("Se actualizó el estatus", {
            duration: 3000,
            position: "top-right",
          });
          return true;
        } else {
          toast.error("Hubo un problema al actualizar el estatus", {
            duration: 3000,
            position: "top-right",
          });
          return false;
        }
      } catch (error) {
        toast.error("Error de red. No se pudo actualizar el estatus", {
          duration: 3000,
          position: "top-right",
        });
        return false;
      }
    }
  };

  const handleChangePassword = ({ id }) => {
    return Swal.fire({
      title: "¿Enviar recuperación de contraseña?",
      text: "Se enviara un correo con la nueva contraseña",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const urlBase = `${process.env.REACT_APP_URL_BASE}/users/${id}/recovery`;
        return await fetch(urlBase, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json; charset=UTF-8",
          },
        }).finally(() => {
          toast.success("Se actualizo el estatus", {
            duration: 3000,
            position: "top-right",
          });
        });
      }
    });
  };

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <div className="rounded-lg border border-gray-300">
          <div className="flex">
            {[
              {
                text: "Mesa de control",
                index: 1,
              },
              {
                text: "Asesores",
                index: 2,
              },
              {
                text: "Asesores externos",
                index: 3,
              },
              {
                text: "Usuarios It",
                index: 4,
              },
              {
                text: "Supervisores",
                index: 5,
              },
            ].map((item) => {
              return (
                <button
                  className={`px-4 py-2  ${
                    active === item.index ? getClassActive() : "bg-gray-200"
                  }`}
                  onClick={() => setActive(item.index)}
                >
                  {item.text}
                </button>
              );
            })}
          </div>
          <div className="p-4">
            <MesaControl
              active={active}
              handleChangeStatus={handleChangeStatus}
              token={token}
              handleChangePassword={handleChangePassword}
            />

            {[2, 3].includes(active) && (
              <AsesoresUsers
                rolId={active === 2 ? 2 : active === 3 ? 3 : ""}
                handleChangeStatus={handleChangeStatus}
                token={token}
                handleChangePassword={handleChangePassword}
              />
            )}

            {[4].includes(active) && (
              <AsesoresUsers
                rolId={1}
                handleChangeStatus={handleChangeStatus}
                token={token}
                handleChangePassword={handleChangePassword}
              />
            )}
            {[5].includes(active) && (
              <AsesoresUsers
                rolId={10}
                handleChangeStatus={handleChangeStatus}
                token={token}
                handleChangePassword={handleChangePassword}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
