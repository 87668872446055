import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import Grid from "components/grid";
import Filter from "components/filter";
import ButtonGeneral from "components/button-general";
import ModalGeneral from "components/modal";
import FormUser from "../form-user";
import Swal from "sweetalert2";

const roleColumnMapping = {
  1: [
    { name: "Datos" },
    { name: "Registro" },
    { name: "Permisos" },
    { name: "Asignar estado" },
    { name: "Contraseña" },
  ],
  10: [
    { name: "Datos" },
    { name: "Registro" },
    { name: "Permisos" },
    { name: "Asignar estado" },
    { name: "Contraseña" },
  ],
  3: [
    { name: "Datos" },
    { name: "Registro" },
    { name: "Permisos" },
    { name: "Asignar estado" },
    { name: "Contraseña" },
  ],
  default: [
    { name: "Datos" },
    { name: "Registro" },
    { name: "Permisos" },
    { name: "Asignar estado" },
    { name: "Documentos" },
    { name: "Contraseña" },
  ],
};

const AsesoresUsers = ({
  rolId,
  handleChangeStatus,
  token,
  handleChangePassword,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectUser, setSelectUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    if (token) {
      getAll();
    }
  }, [token, rolId]);

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/users?idRole=${rolId}`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json && !json.error) {
          setData(json);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className={`py-4`}>
      <Filter
        values={{
          startDate,
          endDate,
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleFilter={getAll}
        showFilterText={true}
        setTextSearch={setTextSearch}
        textSearch={textSearch}
        handleAdd={
          [2, 1, 10].includes(rolId)
            ? () => {
                setShowModal(true);
              }
            : null
        }
      />
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <Grid
          colums={roleColumnMapping[rolId] || roleColumnMapping.default}
          data={data}
          textFilter={textSearch}
          keysFilter={["name", "email"]}
          ComponentRow={({ item, index }) => {
            return (
              <>
                <div className="table-cell p-2">
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Id:</span> {item.id}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Nombre:</span> {item.name}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Correo:</span> {item.email}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Teléfono:</span> {item.phone}
                  </p>
                </div>
                <div className="table-cell p-2">
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Fecha solicitud:</span>{" "}
                    {format(parseISO(item.createdAt), "yyyy-MM-dd")}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Hora registro:</span>{" "}
                    {format(parseISO(item.createdAt), "HH:mm")}
                  </p>
                </div>
                <div className="table-cell p-2">
                  {rolId === 1 ? "Admin" : "Usuario app"}
                </div>
                <div className="table-cell p-2">
                  <div className="flex flex-col items-center gap-2">
                    <ButtonGeneral
                      handleClick={() => {
                        handleChangeStatus({
                          id: item.id,
                          status: !item.active,
                          isDelete: false,
                        }).then(() => {
                          getAll();
                        });
                      }}
                      visible={true}
                    >
                      {item.active ? "Desactivar" : "Activar"}
                    </ButtonGeneral>
                    <ButtonGeneral
                      handleClick={() => {
                        handleChangeStatus({
                          id: item.id,
                          status: false,
                          isDelete: true,
                        }).then(() => {
                          getAll();
                        });
                      }}
                      visible={true}
                    >
                      Eliminar
                    </ButtonGeneral>
                    <ButtonGeneral
                      handleClick={() => {
                        setUser(item);
                        setShowModal(true);
                      }}
                      visible={true}
                    >
                      Editar
                    </ButtonGeneral>
                  </div>
                </div>
                {rolId !== 1 ||
                  rolId !== 3 ||
                  (rolId !== 10 && (
                    <div className="table-cell p-2">
                      <ButtonGeneral
                        handleClick={() => {
                          setSelectUser(item);
                        }}
                        visible={true}
                      >
                        Ver documentos (ine y firma)
                      </ButtonGeneral>
                    </div>
                  ))}
                <div className="table-cell p-2">
                  <ButtonGeneral
                    handleClick={() => {
                      handleChangePassword({ id: item.id });
                    }}
                    visible={true}
                  >
                    Resetear contraseña
                  </ButtonGeneral>
                </div>
              </>
            );
          }}
        />
      )}

      {selectUser && selectUser.id > 0 && (
        <ModalGeneral
          close={() => {
            setSelectUser({});
          }}
          title="Documentos de usuario"
        >
          <div className="h-[450px] w-[690px]  flex-1 overflow-y-scroll px-2">
            {[
              {
                text: "INE FRENTE",
                type: "ine_front",
              },
              {
                text: "INE REVERSO",
                type: "ine_reverso",
              },
              {
                text: "Firma",
                type: "firma",
              },
            ].map((item) => {
              const document = selectUser.documents.find(
                (doc) => doc.type === item.type
              );

              return (
                <div>
                  <h5 className="font-bold">{item.text}</h5>
                  <div className="flex flex-row">
                    <img
                      src={document?.urlFile}
                      className="h-[200px] w-[350px] rounded-[10px] object-contain"
                    />
                    <div className="flex flex-col items-start justify-start gap-2 p-2">
                      <label class="block">
                        <span class="sr-only">Buscar archivo</span>
                        <input
                          type="file"
                          class="dark:text-neutral-500 block w-full text-sm text-gray-500 file:me-4 file:rounded-lg file:border-0 file:bg-blue-600 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-white hover:file:bg-blue-700 file:disabled:pointer-events-none file:disabled:opacity-50 dark:file:bg-blue-500 dark:hover:file:bg-blue-400"
                          id={item.type}
                          onChange={(value) => {
                            setFile(value.target.files[0]);
                          }}
                          accept="image/png, image/jpeg"
                        />
                      </label>
                      <button
                        type="button"
                        class="border-transparent inline-flex items-center gap-x-2 rounded-lg border bg-teal-500 px-4 py-3 text-sm font-semibold text-white hover:bg-teal-600 disabled:pointer-events-none disabled:opacity-50"
                        onClick={() => {
                          const endpoint = `${process.env.REACT_APP_URL_BASE}/users/${selectUser.id}/documents`;
                          if (!file) {
                            return Swal.fire({
                              type: "error",
                              title: "Seleccione el archivo",
                            });
                          }

                          let form = new FormData();
                          form.append("File", file);
                          form.append("Type", item.type);
                          setLoading(true);
                          fetch(endpoint, {
                            method: "POST",
                            headers: {
                              Authorization: "Bearer " + token,
                            },
                            body: form,
                          })
                            .then((res) => res.json())
                            .finally(() => {
                              getAll();
                              setLoading(false);
                            });
                        }}
                      >
                        SUBIR
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </ModalGeneral>
      )}

      {showModal && (
        <FormUser
          title={rolId === 1 ? "Alta usuario admin" : "Alta de usuario"}
          onClose={() => {
            setShowModal(false);
            setUser();
          }}
          handleSucces={() => {
            getAll();
            setUser();

            setShowModal(false);
          }}
          endpoint={process.env.REACT_APP_URL_BASE}
          token={token}
          role={rolId}
          defaultValues={user}
        />
      )}
    </div>
  );
};

export default AsesoresUsers;
